.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.form {
    width: calc(100% - 2em);
    background-color: white;
    padding: 2em 2em;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
}
.form > h2 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 1em;
    font-size: 2rem;
}
.form > input {
    width: 100%;
    height: 40px;
    border: 2px solid lightgray;
    margin-bottom: 2em;
    outline: 0;
    padding: 1em;
    font-size: 1rem;
    transition: all .3s ease-in-out;
}
.form > input:focus {
    border-color: black;
}

.form > button {
    height: 40px;
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    background-color: black;
    color: white;
}