@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
.wrapper {
  width: 100%;
  height: 100vh;
}
.auth_wrapper__1FsvB {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}
.auth_form__2YtKD {
    width: calc(100% - 2em);
    background-color: white;
    padding: 2em 2em;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
}
.auth_form__2YtKD > h2 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 1em;
    font-size: 2rem;
}
.auth_form__2YtKD > input {
    width: 100%;
    height: 40px;
    border: 2px solid lightgray;
    margin-bottom: 2em;
    outline: 0;
    padding: 1em;
    font-size: 1rem;
    transition: all .3s ease-in-out;
}
.auth_form__2YtKD > input:focus {
    border-color: black;
}

.auth_form__2YtKD > button {
    height: 40px;
    width: 100%;
    border: 0;
    outline: 0;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    background-color: black;
    color: white;
}
.blocked_wrapper__PhelI {
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.blocked_wrapper__PhelI > img {
    width: 100%;
    max-width: 800px;
}
.blocked_wrapper__PhelI > a {
    position: fixed;
    bottom: 32px;
    padding: .75em 1.5em;
    background-color: rgb(229, 65, 64);
    color: white;
    width: 240px;
    left: calc(50% - 120px);
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
}
.losowanie_wrapper__3xp-I {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.losowanie_nav__1bwMw {
    width: 100%;
    height: 112px;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.losowanie_box__18sr9 {
    width: 80px;
    height: 80px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
}
.losowanie_box__18sr9 > svg {
    width: 40px;
    height: 40px;
    color: rgb(229, 65, 64);
}
.losowanie_box__18sr9 > p {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.losowanie_content__3C7at {
    width: 100%;
    height: calc(100vh - 112px);
    background-color: white;
    border-top: 2px solid lightgray;
    padding: 16px 16px;
}

.losowanie_winners__iBMfT > h2 {
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.losowanie_drawMachine__32fnZ > h2 {
    font-weight: 500;
    width: 100%;
    text-align: center;
}
.losowanie_drawMachine__32fnZ > p {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 3em;
}

.losowanie_numberBox__1agaG {
    color: silver;
    padding: 10px;
    position: relative;
    width: 50px;
    z-index: 99;
    background: transparent;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

#losowanie_checked__iLNis {
    color: black;
    font-weight: 500;
}

.losowanie_winnerNumbers__exL1r {
    width: 100%;
    height: 80px;
    margin-top: 1em;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.losowanie_winnerNumberShow__3sfJ_ {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: white;
    margin: 0 1em;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.losowanie_winnerNumberShow__3sfJ_ > img {
    width: 40px;
    height: 40px;
}
.losowanie_winnerNumberShow__3sfJ_ > p {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}
.losowanie_drawButtonWrapper__2huJX {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3em 0;
}
.losowanie_drawButtonWrapper__2huJX > button {
    padding: .75em 2.5em;
    border: 0;
    outline: 0;
    background-color: rgb(229, 65, 64);
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.losowanie_winnerInNumbers__2AfYr {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    z-index: -1;
}
.losowanie_winnerInNumbers__2AfYr > img {
    width: 100%;
}

.losowanie_numberList__W5rRX {
    max-width: 720px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
}

#losowanie_noneShow__3Xjup {
    display: none;
}

.losowanie_drawAnimation__3B5Uy {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}
