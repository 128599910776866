.wrapper {
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrapper > img {
    width: 100%;
    max-width: 800px;
}
.wrapper > a {
    position: fixed;
    bottom: 32px;
    padding: .75em 1.5em;
    background-color: rgb(229, 65, 64);
    color: white;
    width: 240px;
    left: calc(50% - 120px);
    text-align: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 1rem;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
}