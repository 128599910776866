.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.nav {
    width: 100%;
    height: 112px;
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.box {
    width: 80px;
    height: 80px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
}
.box > svg {
    width: 40px;
    height: 40px;
    color: rgb(229, 65, 64);
}
.box > p {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}

.content {
    width: 100%;
    height: calc(100vh - 112px);
    background-color: white;
    border-top: 2px solid lightgray;
    padding: 16px 16px;
}

.winners > h2 {
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.drawMachine > h2 {
    font-weight: 500;
    width: 100%;
    text-align: center;
}
.drawMachine > p {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 3em;
}

.numberBox {
    color: silver;
    padding: 10px;
    position: relative;
    width: 50px;
    z-index: 99;
    background: transparent;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

#checked {
    color: black;
    font-weight: 500;
}

.winnerNumbers {
    width: 100%;
    height: 80px;
    margin-top: 1em;
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.winnerNumberShow {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: white;
    margin: 0 1em;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}
.winnerNumberShow > img {
    width: 40px;
    height: 40px;
}
.winnerNumberShow > p {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
}
.drawButtonWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 3em 0;
}
.drawButtonWrapper > button {
    padding: .75em 2.5em;
    border: 0;
    outline: 0;
    background-color: rgb(229, 65, 64);
    color: white;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 0 0px 15px rgb(0 0 0 / 20%);
    cursor: pointer;
}

.winnerInNumbers {
    width: 30px;
    height: 30px;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    z-index: -1;
}
.winnerInNumbers > img {
    width: 100%;
}

.numberList {
    max-width: 720px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
}

#noneShow {
    display: none;
}

.drawAnimation {
    width: 100%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}